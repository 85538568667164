import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

import { MsalGuard } from '@azure/msal-angular';
import {AWSS3BucketSearchComponent} from './awss3-bucket-search/awss3-bucket-search.component'
import {AdminportalComponent} from './adminportal/adminportal.component'



const routes: Routes = [
{path:'bucket',


  component:AWSS3BucketSearchComponent
},
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    component: HomeComponent
  },
  {path:'adminportal',


  component:AdminportalComponent
},
 

];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes , {
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
