import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  authState = new BehaviorSubject<boolean>(false);
  authStateObservable: Observable<boolean> = this.authState.asObservable();
  isAuthenticated = false

  currentUser: User = new User()

  groups: [] = []

  userSubject = new BehaviorSubject<User>(new User());
  userObservable: Observable<User> = this.userSubject.asObservable();

  constructor(private http: HttpClient) {


    this.authStateObservable.subscribe(state => {

      this.isAuthenticated = state

    })

    this.userObservable.subscribe(user => {

      this.currentUser = user

    })


    this.authState.next(false);
  }



}
