export class User{

    givenName?: string
    surname?: string
    displayName?: string
    userPrincipalName?: string
    mail?: string
    id?: string

}
