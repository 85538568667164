import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { map, delay, catchError } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { identifierModuleUrl } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class BucketSearchService {

  API_ENDPOINT = '';

  //bucketlist : BucketList[];
  // API_ENDPOINTSQL = environment.baseUrlDb;
  constructor(private httpclient: HttpClient, private http: HttpClient, private router: Router) {


    if (environment.production) {

      this.API_ENDPOINT = environment.baseUrl

    } else {
      this.API_ENDPOINT = environment.baseUrlDb

    }

    
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };




  public getBucketName() {

    // return this.httpclient.get(this.API_ENDPOINT+'values');
    return this.httpclient.get('https://s3-search-app-dev.s3.us-west-2.amazonaws.com/?prefix=public/', { withCredentials: true });

  }
  getAccountOrders1() {
    const url = (this.API_ENDPOINT + 'values/')

    return this.httpclient.get(url, { withCredentials: true });

  }
  public Get_ADGroupData() {



    return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupDirectoryBucketData');



  }
  public Get_BucketName(id: any) {

    return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupDirectoryDatabyAzureADGroupID?adgroupid=' + id);
  }
  public Get_ADGroupList(id: any) {

    return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupList?azureadgroupid=' + id);

  }

  public Get_AllFiles(id: any) {

    return this.httpclient.get(this.API_ENDPOINT + 'files/loadallfiles?azureadgroupid=' + id);

  }


  public Search(id: any, searchText: string) {

    return this.httpclient.get(this.API_ENDPOINT + 'search/searchbytext?azureadgroupid=' + id + '&searchtext=' + searchText);

  }

  public Get_ADGroupDataAll() {

    return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupDirectoryData');

  }
}
