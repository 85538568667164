import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BucketSearchService } from './shared/bucket-search.service';
import { MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/components/common/selectitem';
import Amplify, { Storage } from 'aws-amplify';

import Auth from '@aws-amplify/auth';
import { element } from 'protractor';
import { elementAt } from 'rxjs-compat/operator/elementAt';
import { S3Client, AbortMultipartUploadCommand, Bucket, IndexDocument, ListObjectsCommand } from '@aws-sdk/client-s3';
// import { S3ClientResolvedConfig, ServiceInputTypes, ServiceOutputTypes } from "../S3Client";

import { getBucketEndpointPlugin } from '@aws-sdk/middleware-bucket-endpoint';
import { getSerdePlugin } from '@aws-sdk/middleware-serde';
import { HttpRequest as __HttpRequest, HttpResponse as __HttpResponse } from '@aws-sdk/protocol-http';
import { Command as $Command } from '@aws-sdk/smithy-client';

import { Injectable, Type } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import {
  FinalizeHandlerArguments,
  Handler,
  HandlerExecutionContext,
  HttpHandlerOptions as __HttpHandlerOptions,
  MetadataBearer as __MetadataBearer,
  MiddlewareStack,
  SerdeContext as __SerdeContext,
} from '@aws-sdk/types';
import { ListObjectsV2Output, ListObjectsV2Request, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { S3ClientResolvedConfig, ServiceInputTypes, ServiceOutputTypes } from '@aws-sdk/client-s3';
// import { environment } from 'src/environments/environment';
import { BucketFileData } from './shared/bucketfiledata.model';
// import { S3ClientResolvedConfig, ServiceInputTypes, ServiceOutputTypes } from "@aws-sdk/S3Client";

export interface ListObjectsV2CommandInput extends ListObjectsV2Request { }
export interface ListObjectsV2CommandOutput extends ListObjectsV2Output, __MetadataBearer { }
import * as S3 from 'aws-sdk/clients/s3';
import { Console, count, error } from 'console';

import { camelCase } from 'lodash';
import { UserService } from '../services/user.service';
import { ADGroup } from './shared/adgroup.model';
import { Directory } from '../adminportal/shared/directory.model';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { IoTThingsGraph } from 'aws-sdk';
import { AdgroupDirectory } from '../models/adgroupdirectory.model';
import { TreeNode } from 'primeng/api';
import { TreeTable } from 'primeng/treetable';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter, distinctUntilChanged, map, subscribeOn } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {S3File} from '../models/s3File.model';

@Component({
  selector: 'app-awss3-bucket-search',
  templateUrl: './awss3-bucket-search.component.html',
  styleUrls: ['./awss3-bucket-search.component.css'],
  providers: [MessageService]
})

export class AWSS3BucketSearchComponent implements OnInit {
  cols: any[];
  data: any[];
  filedata: Array<BucketFileData> = [];
  allFiles: Array<BucketFileData> = [];

  bucketdata: any;
  authenticated: boolean;
  fileName: '';
  addbucket: any;
  bucket: any;
  bucketName: any;
  az12ad3gi45d: any; // azureadgroupid: any;
  // ad67az8ad9gi10d:any;//adazureadgroupid: any;

  baseUrl = 'https://s3-search-app-dev.s3.us-west-2.amazonaws.com/public/';
  aoabaseUrl = 'https://aoaarchives.s3.amazonaws.com/';
  // jBucketFileName: 'aoaarchives';
  filedata1: [];
  // omcrbucket: any;
  groups: [];
  arr3 = [];
  selectBucket: any;
  selectBucketdata: any;
  ADGroupData: ADGroup[];
  // aw12ac34a567ky:any;//AWSAccessKey: any;
  // aw89c1011ci12d:any;//AWSClientID: any;

  // awsaccesskey: any;
  // awsclientid: any;
  EncryptData: any;

  DistBucketData: any;

  // FolderName:any;
  BreadCrumbsFolder: string[];
  // breadcrumbs$: Observable<BreadCrumb[]>;
  CurrentFilePath: any;
  CurrentBucketName: any;
  loading = false;
  first = 0;
  constructor(private router: Router, 
  private route: ActivatedRoute, private authService: MsalService, private bucketsearchservice: BucketSearchService, private userServ: UserService, private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef) {


    this.userServ.authStateObservable.subscribe(state => {


      this.authenticated = state;

      if (this.authenticated === true) {

        // this.getUserBuckets()
        // this.breadcrumbs$ = bucketsearchservice.breadcrumbs$;
      }

    });

  }

  ngOnInit() {

    // this.loading = true;
    if (this.authenticated === true) {

      const activeAccount = this.authService.instance.getAllAccounts()[0];

      this.authService.acquireTokenSilent({
        scopes: ['group.Read.All', 'profile', 'User.Read'],
        account: activeAccount
      }).subscribe(result => {

        const idToken = result.idTokenClaims;
        const idTokenJson = JSON.stringify(idToken);
        const IdTokenObj = JSON.parse(idTokenJson);

        this.userServ.groups = IdTokenObj.groups;
        // this.azureadgroupid = IdTokenObj.groups
        this.az12ad3gi45d = IdTokenObj.groups;
        this.ADGroupData = [];
        // console.log("ADGroups: " + this.az12ad3gi45d.toString())

        this.bucketsearchservice.Get_ADGroupList(this.az12ad3gi45d).subscribe((result: ADGroup[]) => {
          this.bucketdata = result;

          this.bucket = this.bucketdata[0].bucketName;

          result.forEach(item => {
            if (item.awsAccessKey != '') {

              this.bucket = item.bucketName.toString();
              const adgObj = new ADGroup();
              /*adgObj.BucketName = item.bucketName.toString();
              adgObj.DirectoryPath = item.directoryPath.toString();
              adgObj.Region = item.region;*/
              adgObj.bucketName = item.bucketName.toString();
              // console.log("Bucket"+item.bucketName.toString())
              adgObj.directoryPath = item.directoryPath.toString();
              adgObj.region = item.region;
              adgObj.folderPath = item.folderPath;
              adgObj.folderRestriction = item.folderRestriction;

              let ac123k45y: any; // let accesskey: any;
              let k1234i67d: any; // let keyid: any;
              if (item.awsAccessKey) { ac123k45y = item.awsAccessKey.toString(); }
              // console.log("AccessKey1111"+ item.awsAccessKey.toString())//n
              if (item.awsClientID) { k1234i67d = item.awsClientID.toString(); }

              const se123cr65 = 'STl0b*7b6d0&';

              ac123k45y = CryptoJS.AES.decrypt(ac123k45y, se123cr65);
              // adgObj.AWSAccessKey = accesskey.toString(CryptoJS.enc.Utf8);
              adgObj.awsAccessKey = ac123k45y.toString(CryptoJS.enc.Utf8);
              // console.log("AccessKey"+ac123k45y.toString(CryptoJS.enc.Utf8))//n
              k1234i67d = CryptoJS.AES.decrypt(k1234i67d, se123cr65);
              // adgObj.AWSClientID = keyid.toString(CryptoJS.enc.Utf8);
              adgObj.awsClientID = k1234i67d.toString(CryptoJS.enc.Utf8);
              // console.log("ClientID"+k1234i67d.toString(CryptoJS.enc.Utf8))//n
              this.ADGroupData.push(adgObj);
            }
          });

          // this.getUserBuckets();
          this.getdynamicdistinctbucketdata();




        });
      });

    }
    this.cols = [
      { field: 'key', header: 'Download' },
      { field: 'FileName', header: 'File Name' },
      { field: 'Bucket ', header: 'Bucket' },
      { field: 'DateOfUpload', header: 'Date Of Uploads' }
    ];

    this.groups = this.userServ.groups;

  }

  getUserBuckets(prefix: string= '', folderselected= false) {

    /*Start AOA Bucket*/
    const fData = [];
    let s3URL = '';
    let params = {};
    this.ADGroupData.forEach(element => {

      if (folderselected) {
        if (element.folderRestriction) {

          params = {

            Bucket: element.bucketName,

            Prefix: prefix,

            Delimiter: '/',

          };
        } else {

          params = {

            Bucket: element.bucketName,

            Prefix: prefix,
            Delimiter: '/',

          };
        }
      } else {
        if (element.folderRestriction) {

          params = {


            Bucket: element.bucketName,

            Prefix: element.folderPath,

            Delimiter: '/',

          };
        } else {

          params = {

            Bucket: element.bucketName,

            Prefix: prefix,
            Delimiter: '/',

          };
        }
      }

      // let bFileName = element.BucketName;
      let bFileName = element.bucketName;
      const dArray = [];
      // let bUrl = element.DirectoryPath;
      const bUrl = element.directoryPath;
      // console.log("S3URL " + s3URL)
      // let devBucket = element.BucketName;
      const devBucket = element.bucketName;
      if (element.bucketName == 'nobi-mah-scandesign') {
        element.region = 'us-east-1';

      }
      // let dateofupload=element.dateOfUpload;
      const aoabucket = this.getS3Bucket(element.awsAccessKey, element.awsClientID, element.region);
      aoabucket.listObjects(params, function(err, s3data) {
        if (err) {
          console.log('There was an error getting your files: ' + err);
          return; ////////////////// ;
        }
        const fileDatas = s3data.Contents;
        const folders = s3data.CommonPrefixes;
        const arr1 = [];

        folders.forEach(element => {
          // console.log(element.Prefix);
          const listobj: BucketFileData = new BucketFileData();
          listobj.FileFolder = 'Folder';
          // listobj.FolderName=element.Prefix;
          listobj.FileName = element.Prefix;
          // alert(listobj.FileName=element.Prefix)
          // alert(listobj.FolderName=element.Prefix + "Folder")
          listobj.Bucket = devBucket;

          arr1.push(listobj);
        });
        // console.log(folders);
        s3URL = element.directoryPath;
        const json = JSON.stringify(s3data);
        // console.log("TestData " +json)
        // const contents = JSON.parse(data);

        fileDatas.forEach(fileelement => {
          const s3search = {
            key: '',
            lastModified: '',
            eTag: '',
            downloadfileurl: ''

          };
          if (fileelement.key != '') {

            const listobj: BucketFileData = new BucketFileData();
            listobj.FileName = fileelement.Key;
            listobj.FileFolder = 'File';
            // alert(listobj.FileName=element.Key);
            bFileName = fileelement.Key;
            // console.log('FileName' + bFileName);

            listobj.DateOfUpload = fileelement.LastModified;
            listobj.Bucket = devBucket;
            // listobj.Bucket=params.Bucket;
            s3search.key = fileelement.key;
            s3search.lastModified = fileelement.LastModified;
            fileelement.downloadfileurl = s3URL + fileelement.Key;

            listobj.Bucket = element.bucketName;
            if (element.bucketName == 'nobi-mah-scandesign') {
              element.region = 'us-east-1';

            }
            if (fileelement.downloadfileurl != '') {
              const url = s3URL + fileelement.Key;
              const encodURL = encodeURI(url);
              listobj.DownLoadFileURL = encodURL;


            }
            arr1.push(listobj);

          }
          this.filedata = fData;


        });

        arr1.forEach(element => {

          fData.push(element);

        });

        if (err) {
          console.log('There was an error getting your files: ' + err);
          return;
        }

      });
      // }//else close
    });

    setTimeout(
        () => {
          this.filedata = fData;
          this.data = this.filedata;
        },
        2000
    );

    /*End AOA Bucket*/
    this.loadAllFiles();

  }
  async searchAllFiles(searchText: string = '') {

    this.messageService.add({ severity: 'info', summary: 'Searching Databse', detail: 'Searching files...' });

    // searchText = searchText.target.files[0];
    // console.log(searchText);
    // this.filename = this.file.name
    let searchedFiles: BucketFileData[] = [];

    searchText = searchText.trim();

      this.loading = true;

    if (searchText === '') {

      this.data = this.filedata;

    } else {

      
      // this.allFiles=this.allFiles.map(a=>a.FileName.toLowerCase())

      // searchedFiles = await this.allFiles.filter(f => f.FileName.toLowerCase().includes(searchText.toLowerCase()));

      // tslint:disable-next-line:triple-equals
      if(searchedFiles.length == 0) {

        console.log('Searched files length = 0.  Calling API Search');
       await this.bucketsearchservice.Search(this.az12ad3gi45d, searchText).subscribe( (result: S3File[]) => {

         this.data = [];

         // console.log('Files Found via API: ' + result.length.toString());
         result.forEach( file => {

           let fileData = new BucketFileData()

           fileData.FileName = file.key;
           fileData.DownLoadFileURL = file.downloadfileurl;
           fileData.DateOfUpload = new Date(file.lastmodified);
           fileData.FileFolder = 'File';
            
            console.log( fileData.DateOfUpload = new Date(file.lastmodified));
           if(file.downloadfileurl.includes('aoa')){

             fileData.Bucket = 'aoaarchives';

           }else if(file.downloadfileurl.includes('ormco')){

             fileData.Bucket = 'ormco-spark-cloud-archive-prod';

           }else if(file.downloadfileurl.includes('nobi')){

             fileData.Bucket = 'nobi-mah-scandesign';

           }else if(file.downloadfileurl.includes('envistat')){

             fileData.Bucket = 'envistatuuop3dx1';

            }else if(file.downloadfileurl.includes('aligners')){

              fileData.Bucket='aligners-prod-usea1';
            }




            this.data.push(fileData);

          });
        });

       
      } else {

        this.data = searchedFiles;
      }




    }
    this.reset();

    this.loading = false;

  }

  async searchAllFilesV2(){



  }


  fileMatches(object: BucketFileData, text: string) {

    if (object.FileName.includes(text)) {

      return true;

    } else {

      return false;
    }

  }

  async loadAllFiles() {

    this.loading = true;
    // console.log("Data123"+this.ADGroupData);
    /*Start AOA Bucket*/
    const fData = [];
    let s3URL = '';
    let params = {Bucket: '', Prefix: '', Delimiter: ''};
    this.ADGroupData.forEach(async element => {
      // console.log("ADGroupName"+element.bucketName)
      if (element.folderRestriction) {
        // console.log("folderpath"+element.folderPath);
        params = {

          // Bucket: element.BucketName,
          Bucket: element.bucketName,
          Prefix: element.folderPath, // prefix,

          Delimiter: '',

        };
      } else {
        // alert("else")
        params = {
          // Bucket: element.BucketName,
          Bucket: element.bucketName,

          Prefix: '',
          Delimiter: ''

        };

      }


      // let bFileName = element.BucketName;
      let bFileName = element.bucketName;
      const dArray = [];
      // let bUrl = element.DirectoryPath;
      const bUrl = element.directoryPath;
      // console.log("S3URL " + s3URL)
      // let devBucket = element.BucketName;
      const devBucket = element.bucketName;
      if (element.bucketName == 'nobi-mah-scandesign') {
        element.region = 'us-east-1';

      }
      let moreFiles = false;
      let nextKey = '';



      const awss3client = this.getS3ClientBucket(element.awsAccessKey, element.awsClientID, element.region);
      // let dateofupload=element.dateOfUpload;
      // var command=awss3client.ListObjectsCommand(params).Promise

      try {
        let isTruncated = true;
        // console.log("PArams"+ params.Bucket)
        // console.log("Your bucket contains the following objects:\n");
        const contents = '';
        const command = new ListObjectsCommand(params);
        while (isTruncated) {
          // const { Contents, IsTruncated } =
          // console.log("Command "+ command)
          const response = await awss3client.send(command);
          // console.log("Response "+ response)
          isTruncated = response.IsTruncated;
          moreFiles = response.IsTruncated;
          // console.log("promise Response")
          // console.log("truncate"+moreFiles)
          if (moreFiles) {
            const lastKeyIndex = response.Contents.length - 1;
            nextKey = response.Contents[lastKeyIndex].Key;
            // console.log("marker"+nextKey)
            command.input.Marker = nextKey;
          }
          const fileDatas = response.Contents;
          // js const folders=s3data.CommonPrefixes;
          // console.log("bucketname "+ element.bucketName + " Count "+fileDatas.length )
          const arr1 = [];

          s3URL = element.directoryPath;
          const json = JSON.stringify(response.Contents);

          fileDatas.forEach(fileelement => {
            const s3search = {
              key: '',
              lastModified: '',
              eTag: '',
              downloadfileurl: ''

            };
            if (fileelement.key != '') {

              const listobj: BucketFileData = new BucketFileData();
              listobj.FileName = fileelement.Key;
              listobj.FileFolder = 'File';
              // alert(listobj.FileName=element.Key);
              bFileName = fileelement.Key;
              // console.log('FileName' + bFileName);

              listobj.DateOfUpload = fileelement.lastmodified;
              listobj.Bucket = devBucket;
              // listobj.Bucket=params.Bucket;
              s3search.key = fileelement.key;
              s3search.lastModified = fileelement.LastModified.toString();
              fileelement.downloadfileurl = s3URL + fileelement.Key;
            //alert(listobj.DateOfUpload = fileelement.LastModified);
            console.log(listobj.DateOfUpload = fileelement.lastmodified);
              listobj.Bucket = element.bucketName;
              if (element.bucketName == 'nobi-mah-scandesign') {
                element.region = 'us-east-1';

              }
              if (fileelement.downloadfileurl != '') {
                const url = s3URL + fileelement.Key;
                const encodURL = encodeURI(url);
                listobj.DownLoadFileURL = encodURL;


              }
              arr1.push(listobj);

            }
            // this.filedata = fData;


          });

          arr1.forEach(element => {

            fData.push(element);


          });


        }
      } catch (err) {

      }


    });

    setTimeout(
        () => {
          this.allFiles = fData;
          // this.data = this.filedata;
          // console.log("Count"+fData.length)
          // console.log("Count**"+this.allFiles.length)
          // alert("5")
          this.loading = false;
        },
        8000

    );



  }


  async loadAllFilesV2() {

    console.log('Load All Files V2 called');
    const arr1 = [];
    const fData = [];
    this.bucketsearchservice.Get_AllFiles(this.az12ad3gi45d).subscribe((result: S3File[]) => {


      result.forEach( file =>{


        const listobj: BucketFileData = new BucketFileData();
        listobj.FileName = file.key;
        listobj.FileFolder = 'File';
        // alert(listobj.FileName=element.Key);
        // bFileName = fileelement.Key;
        // console.log('FileName' + bFileName);

        listobj.DateOfUpload = new Date(file.lastmodified);

        if(file.downloadfileurl.includes('aoa')){

          listobj.Bucket = 'aoaarchives';
        } else if(file.downloadfileurl.includes('ormco')){
          listobj.Bucket = 'Ormco-sparck-cloud-prod';

        } else if(file.downloadfileurl.includes('nobi')){
          listobj.Bucket = 'nobi-scandesign';

        } else if(file.downloadfileurl.includes('evnistatuuop')){

          listobj.Bucket = 'ennvistatuuop3dx1';

        }else if(file.downloadfileurl.includes('aligners')){
              
          listobj.Bucket='aligners-prod-usea1';
            }


        // listobj.Bucket = devBucket;
        // listobj.Bucket=params.Bucket;
        // s3search.key = fileelement.key;
        // s3search.lastModified = fileelement.LastModified.toString();
        // fileelement.downloadfileurl = file;

        // listobj.Bucket = element.bucketName;
        // if (element.bucketName == 'nobi-mah-scandesign') {
        //   element.region = 'us-east-1';
        //
        // }
        if (file.downloadfileurl != '') {
          const url = file.downloadfileurl;
          const encodURL = encodeURI(url);
          listobj.DownLoadFileURL = encodURL;


        }
        arr1.push(listobj);


      });



      console.log('Count of arr1' + arr1.length.toString());
      arr1.forEach(element => {

        fData.push(element);




      });

    });




  }


  fileLoading(searchText: string = '') {
    /*Start AOA Bucket*/
    const fData = [];
    let s3URL = '';
    this.ADGroupData.forEach(element => {
      const params = {

        Bucket: element.bucketName,

      };
      let bFileName = element.bucketName;
      const dArray = [];
      const bUrl = element.directoryPath;
      // console.log("S3URL " + s3URL)
      const devBucket = element.bucketName;
      const aoabucket = this.getS3Bucket(element.awsAccessKey, element.awsClientID, element.region);
      aoabucket.listObjects(params, function(err, s3data) {
        if (err) {
          console.log('There was an error getting your files: ' + err);
          return;
        }
        const fileDatas = s3data.Contents;
        s3URL = element.directoryPath;
        const json = JSON.stringify(s3data);
        // console.log("TestData " +json)
        // const contents = JSON.parse(data);
        const arr1 = [];
        fileDatas.forEach(fileelement => {
          const s3search = {
            key: '',
            lastModified: '',
            eTag: '',
            downloadfileurl: ''

          };
          if (fileelement.key != '') {

            const listobj: BucketFileData = new BucketFileData();
            listobj.FileName = fileelement.Key;
            listobj.FileFolder = 'File';
            // alert(listobj.FileName=element.Key);
            bFileName = fileelement.Key;
            // console.log('FileName' + bFileName);

            listobj.DateOfUpload = fileelement.lastmodified;
            listobj.Bucket = devBucket;
            // listobj.Bucket=params.Bucket;
            s3search.key = fileelement.key;
            s3search.lastModified = fileelement.LastModified;
            fileelement.downloadfileurl = s3URL + fileelement.Key;

            if (fileelement.downloadfileurl != '') {
              const url = s3URL + fileelement.Key;
              const encodURL = encodeURI(url);
              listobj.DownLoadFileURL = encodURL;
            }



            arr1.push(listobj);

          }
          this.filedata = fData;


        });

        arr1.forEach(element => {

          fData.push(element);

        });

        if (err) {
          console.log('There was an error getting your files: ' + err);
          return;
        }

      });

    });
    // this.filedata = fData;
    // this.data = this.filedata;
    setTimeout(
        () => {
          this.filedata = fData;
          this.data = this.filedata;
        },
        2000
    );

    /*End AOA Bucket*/


  }

  async loadAllFilesPaginator(nextKey: string, adGroup: ADGroup) {

    /*Start AOA Bucket*/
    const fData = [];
    let s3URL = '';
    let moreFiles = false;
    let continuationKey = nextKey;
    let countOfLoops = 0;
    let params = {};
    do {
      // console.log("countOFLoops " +countOfLoops)

      if (adGroup.folderRestriction) {
        // console.log("folderpath"+element.folderPath);
        params = {

          // Bucket: element.BucketName,
          Bucket: adGroup.bucketName,
          Prefix: adGroup.folderPath, // prefix,
          Marker: continuationKey
          // js  Delimiter: '/',

        };
      } else {
        // alert("else")
        params = {
          // Bucket: element.BucketName,
          Bucket: adGroup.bucketName,
          Marker: continuationKey
          // js Prefix:prefix,// '',//archive/D1234567_test/
          // js Delimiter: '/',
        };
      }
      // let bFileName = element.BucketName;
      let bFileName = adGroup.bucketName;
      const dArray = [];
      // let bUrl = element.DirectoryPath;
      const bUrl = adGroup.directoryPath;
      // console.log("S3URL " + s3URL)
      // let devBucket = element.BucketName;
      const devBucket = adGroup.bucketName;
      if (adGroup.bucketName == 'nobi-mah-scandesign') {
        adGroup.region = 'us-east-1';
      }

      // let dateofupload=element.dateOfUpload;
      const aoabucket = this.getS3Bucket(adGroup.awsAccessKey, adGroup.awsClientID, adGroup.region);

      await aoabucket.listObjects(params, await function(err, s3data) {
        if (err) {
          console.log('There was an error getting your files: ' + err);
          return;
        }
        const fileDatas = s3data.Contents;
        // js const folders=s3data.CommonPrefixes;
        moreFiles = s3data.IsTruncated;
        // console.log("Load MoreFiles "+ moreFiles)
        // console.log("Count Files"+s3data.Contents.length )

        const arr1 = [];
        /* folders.forEach(element => {
          console.log(element.Prefix);
          const listobj: BucketFileData = new BucketFileData();
          listobj.FileFolder='Folder';
          listobj.FileName=element.Prefix;
          listobj.Bucket = devBucket;
          arr1.push(listobj)
        });*/
        // console.log(folders);
        s3URL = adGroup.directoryPath;
        const json = JSON.stringify(s3data);
        // console.log("TestData " +json)
        // const contents = JSON.parse(data);
        fileDatas.forEach(fileelement => {
          const s3search = {
            key: '',
            lastModified: '',
            eTag: '',
            downloadfileurl: ''
          };
          if (fileelement.key != '') {
            const listobj: BucketFileData = new BucketFileData();
            listobj.FileName = fileelement.Key;
            listobj.FileFolder = 'File';
            // alert(listobj.FileName=element.Key);
            bFileName = fileelement.Key;
            // console.log('FileName' + bFileName);
            listobj.DateOfUpload = fileelement.lastmodified;
            listobj.Bucket = devBucket;
            // listobj.Bucket=params.Bucket;
            s3search.key = fileelement.key;
            s3search.lastModified = fileelement.LastModified.toString();
            fileelement.downloadfileurl = s3URL + fileelement.Key;
            listobj.Bucket = adGroup.bucketName;
            if (adGroup.bucketName == 'nobi-mah-scandesign') {
              adGroup.region = 'us-east-1';
            }
            if (fileelement.downloadfileurl != '') {
              const url = s3URL + fileelement.Key;
              const encodURL = encodeURI(url);
              listobj.DownLoadFileURL = encodURL;

            }
            arr1.push(listobj);
          }
          // this.filedata = fData;

        });
        arr1.forEach(element => {
          fData.push(element);
        });
        if (err) {
          console.log('There was an error getting your files: ' + err);
          return;
        }
        if (moreFiles) {
          /*continuationKey = s3data.KeyMarker;
          console.log("marker"+continuationKey)*/
          const lastKeyIndex = s3data.Contents.length - 1;
          continuationKey = s3data.Contents[lastKeyIndex].Key;
          // console.log("continuationKey " +continuationKey)
          // js this.IterateLoadAllFilePaginator(continuationKey,adGroup);
        }

      });
      countOfLoops = countOfLoops++;
      // console.log("MoreFileEnd Of Do Whilw"+moreFiles)
    }while (moreFiles) ;

    this.allFiles.concat(fData);
    // this.filedata = fData;
    // this.data = this.filedata;
    // setTimeout(
    //     () => {
    //       this.filedata = fData;
    //       this.data = this.filedata;
    //     },
    //     2000
    // );
    //

  }


  IterateLoadAllFilePaginator(key: string, adGroup: ADGroup) {
    this.loadAllFilesPaginator(key, adGroup);
  }




  getdynamicdistinctbucketdata() {

    this.DistBucketData = this.bucketdata;
    /*  this.bucketsearchservice.Get_ADGroupData().subscribe((result: ADGroup[]) => {
        const json = JSON.stringify(result);
        const data = JSON.parse(json);
        this.DistBucketData = data;

      })*/
  }



  getselectedbucketdata(selectedbucket, prefix: string= '') {

    // this.BreadCrumbsFolder = [];
    // /*Start AOA Bucket*/
    // const fData = [];
    // let s3URL = '';

    // // this.ADGroupData.forEach(element => {
    // const sbucketindex = this.ADGroupData.findIndex(x => x.bucketName === selectedbucket);
    // const element: ADGroup = this.ADGroupData[sbucketindex];
    // let params = {};
    // if (element.folderRestriction) {
    //   params = {

    //     // Bucket: element.BucketName,
    //     Bucket: selectedbucket,

    //     Prefix: element.folderPath, // prefix,
    //     Delimiter: '/',

    //   };
    // } else {
    //   params = {

    //     // Bucket: element.BucketName,
    //     Bucket: selectedbucket,

    //     Prefix: prefix, // '',//archive/D1234567_test/
    //     Delimiter: '/',

    //   };
    // }
    // let bFileName = selectedbucket; // element.BucketName;
    // const dArray = [];
    // // let bUrl = element.DirectoryPath;
    // const bUrl = element.directoryPath;
    // // console.log("S3URL " + s3URL)
    // const devBucket = selectedbucket; // element.BucketName;
    // const aoabucket = this.getS3Bucket(element.awsAccessKey, element.awsClientID, element.region);

    // aoabucket.listObjects(params, function(err, data) {
    //   if (err) {
    //     console.log('There was an error getting your files: ' + err);
    //     return;
    //   }
    //   const fileDatas = data.Contents;
    //   const folders = data.CommonPrefixes;
    //   const arr1 = [];

    //   folders.forEach(element => {

    //     const listobj: BucketFileData = new BucketFileData();
    //     listobj.FileFolder = 'Folder';
    //     // listobj.FolderName=element.Prefix;
    //     listobj.FileName = element.Prefix;
    //     // alert(listobj.FileName=element.Prefix)
    //     // alert(listobj.FolderName=element.Prefix + "Folder")
    //     listobj.Bucket = devBucket;

    //     arr1.push(listobj);
    //   });
    //   // console.log(folders);
    //   s3URL = element.directoryPath;
    //   const json = JSON.stringify(data);
    //   // console.log("TestData " +json)
    //   // const contents = JSON.parse(data);

    //   fileDatas.forEach(fileelement => {
    //     const s3search = {
    //       key: '',
    //       lastModified: '',
    //       eTag: '',
    //       downloadfileurl: ''

    //     };
    //     if (fileelement.key != '') {

    //       const listobj: BucketFileData = new BucketFileData();
    //       listobj.FileName = fileelement.Key;
    //       listobj.FileFolder = 'File';
    //       // alert(listobj.FileName=element.Key);
    //       bFileName = fileelement.Key;
    //       // console.log('FileName' + bFileName);

    //       listobj.DateOfUpload = fileelement.LastModified;
    //       listobj.Bucket = devBucket;
    //       // listobj.Bucket=params.Bucket;
    //       s3search.key = fileelement.key;
    //       s3search.lastModified = fileelement.LastModified;
    //       fileelement.downloadfileurl = s3URL + fileelement.Key;

    //       listobj.Bucket = element.bucketName;
    //       // alert("test2");
    //       if (element.bucketName == 'nobi-mah-scandesign') {

    //         element.region = 'us-east-1';
    //         // alert(element.region);


    //       }

    //       if (fileelement.downloadfileurl != '') {
    //         const url = s3URL + fileelement.Key;
    //         const encodURL = encodeURI(url);
    //         listobj.DownLoadFileURL = encodURL;


    //       }
    //       arr1.push(listobj);

    //     }
    //     this.filedata = fData;


    //   });

    //   arr1.forEach(element => {

    //     fData.push(element);

    //   });

    //   if (err) {
    //     console.log('There was an error getting your files: ' + err);
    //     return;
    //   }

    // });

    // this.filedata = fData;
    // this.data = this.filedata;
    // /*End AOA Bucket*/

  }

  reset() {
    this.first = 0;
  }

  onRowSelect(event) {
    // console.log(event.data.downloadfileurl);
  }
  async BreadCrumNavigation(BFolderName: any) {
    // if(BFolderName=='home')
    if (BFolderName == this.CurrentBucketName) {
      // alert(BFolderName);
      this.BreadCrumbsFolder = [];
      // newFolderPath = '';

      // this.getUserBuckets(newFolderPath);
      this.getselectedbucketdata(this.CurrentBucketName);


    } else {
      // alert(BFolderName);
      const indexOfFolder = this.CurrentFilePath.indexOf(BFolderName) + BFolderName.length;
      // console.log("index of"+indexOfFolder)
      let newFolderPath = this.CurrentFilePath.slice(0, indexOfFolder);
      // console.log("NewPath  "+newFolderPath);
      this.getUserBuckets(newFolderPath + '/');
      // newFolderPath='home/'+newFolderPath
      newFolderPath = this.CurrentBucketName + '/' + newFolderPath;
      this.BreadCrumbsFolder = newFolderPath.split('/');

      // this.BreadCrumbsFolder=this.BreadCrumbsFolder.splice(0,this.BreadCrumbsFolder.length-1);
      // this.BreadCrumbsFolder.pop();
      const lastElement = this.BreadCrumbsFolder.splice(-1, 1);
      // console.log("LastElement " +lastElement)

      // console.log("BFolder " +this.BreadCrumbsFolder)

    }


  }

  async downloadMyFile(dfilename: any, dbucketname: any) {
    this.CurrentBucketName = dbucketname;
    // alert(dbucketname);
    // this.CurrentFilePath='Home/'+dfilename;
    this.CurrentFilePath = dfilename;

    // console.log("path "+ this.CurrentFilePath);
    const lastChar = dfilename.charAt(dfilename.length - 1);
    // lastChar=dbucketname.charAt(dbucketname.length-1);

    if (lastChar == '/') {

      this.getUserBuckets(dfilename, true);
      // dfilename='home/'+dfilename;
      dfilename = this.CurrentBucketName + '/' + dfilename;
      // alert(dfilename);

      this.BreadCrumbsFolder = dfilename.split('/');
      this.BreadCrumbsFolder = this.BreadCrumbsFolder.splice(0, this.BreadCrumbsFolder.length - 1);
      // this.BreadCrumbsFolder.pop();
      const lastElement = this.BreadCrumbsFolder.splice(-1, 1);
      // console.log("LastElement " +lastElement)

      // console.log("BFolder " +this.BreadCrumbsFolder)

    } else {
      const adgarr: ADGroup[] = this.ADGroupData.filter(x => x.bucketName == dbucketname);
      const adg = adgarr[0];
      const bucket = new S3(
          {

            region: adg.region, // 'us-east-1',
            credentials: {
              secretAccessKey: adg.awsAccessKey,
              accessKeyId: adg.awsClientID

            },
            signatureVersion: 'v4'
          }
      );

      const params = {

        Bucket: dbucketname,
        Key: dfilename,

      };
      const url = bucket.getSignedUrl('getObject', {
        Bucket: dbucketname,
        Key: dfilename,
        Expires: 300
      });
      // console.log("URL "+url);
      window.open(url);
    }
  }


  private getS3Bucket(accesskey: string, keyid: string, bregion: string): any {
    // console.log("gets3bucket method "+ keyid)
    const bucket = new S3(
        {

          region: bregion, // 'us-east-1',
          credentials: {
            secretAccessKey: accesskey,
            accessKeyId: keyid,
          },
          signatureVersion: 'v4'
        }
    );
    return bucket;
  }

  private getS3ClientBucket(accesskey: string, keyid: string, bregion: string): any {
    // console.log("gets3bucket method "+ keyid)
    const bucket = new S3Client(
        {

          region: bregion, // 'us-east-1',
          credentials: {
            secretAccessKey: accesskey,
            accessKeyId: keyid,
          }
          // signatureVersion:'v4'
        }
    );
    return bucket;
  }

  // }

}


export class ListObjectsV2 extends $Command<
    ListObjectsV2CommandInput,
    ListObjectsV2CommandOutput,
    S3ClientResolvedConfig
    > {
  // Start section: command_properties
  // End section: command_properties

  constructor(readonly input: ListObjectsV2CommandInput) {
    // Start section: command_constructor
    super();
    // End section: command_constructor
  }


  resolveMiddleware(
      clientStack: MiddlewareStack<ServiceInputTypes, ServiceOutputTypes>,
      configuration: S3ClientResolvedConfig,
      options?: __HttpHandlerOptions
  ): Handler<ListObjectsV2CommandInput, ListObjectsV2CommandOutput> {
    // this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
    this.middlewareStack.use(getBucketEndpointPlugin(configuration));

    const stack = clientStack.concat(this.middlewareStack);

    const { logger } = configuration;
    const clientName = 'S3Client';
    const commandName = 'ListObjectsV2Command';
    const handlerExecutionContext: HandlerExecutionContext = {
      logger,
      clientName,
      commandName,
      inputFilterSensitiveLog: ListObjectsV2Request.filterSensitiveLog,
      outputFilterSensitiveLog: ListObjectsV2Output.filterSensitiveLog,
    };
    const { requestHandler } = configuration;
    return stack.resolve(
        (request: FinalizeHandlerArguments<any>) =>
            requestHandler.handle(request.request as __HttpRequest, options || {}),
        handlerExecutionContext
    );
  }

}

