import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ADGroup } from './shared/adgroup.model';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/components/common/selectitem';
import { Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { AdminPortalServiceService } from './shared/admin-portal-service.service';
import { verifyHostBindings } from '@angular/compiler';

import * as CryptoJS from 'crypto-js';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-adminportal',
  templateUrl: './adminportal.component.html',
  styleUrls: ['./adminportal.component.css']
})
export class AdminportalComponent implements OnInit {

  ADGroupData: ADGroup[];
  cols: any[];
  displayDialog: boolean = false;
  adminPortal: boolean;
  selected_IsActive: boolean;
  directoryid: number;
  adgroupid: number;
  directorypath: any;
  bucketname: any;
  adgroupname: any;
  directoryData: any;
  azureadgroupid:any;
  azureadgroupdata:any;
  ADGroupName:any;
  AzureADGroupID:any;

  AWSAccessKey:any;
  AWSClientID:any;
  authenticated = false;
  displayName = ''
  firstName = ''
  lastName = ''
  email = ''
  region:any;
  newazureadgroupid:any;
  adGroupName:any;
  bucketName:any;
  directoryPath:any;
  awsaccesskey:any;
  awsclientid:any;
  isDisabled: boolean = true;
  folderRestriction: boolean = false;
  folderPath:any;
  constructor(private _adminPortalServiceService: AdminPortalServiceService, private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef, private router: Router,private userServ: UserService) {
      this.userServ.authStateObservable.subscribe(state => {
        this.authenticated = state;
  
      });
        this.email=this.userServ.currentUser.mail
        
     }

  ngOnInit() {
   this.azureadgroupid=sessionStorage.getItem('GroupID');
    if(this.azureadgroupid.includes("652fa8b7-cd5d-417b-929c-7185c3356240") || this.email == 'Chris.Tobler@envistaco.com' || this.email == 'chris.tobler@envistaco.com' || this.email == 'diego.muniz@envistaco.com' || this.email == 'Diego.Muniz@envistaco.com' || this.email == 'Yonathan.Seleshi@envistaco.com' || this.email == 'yonathan.seleshi@envistaco.com' || this.email == 'sandeep.jamkar@envistaco.com' )
    {
      this._adminPortalServiceService.Get_ADGroupData().subscribe((result: ADGroup[]) => {
      this.ADGroupData = result;
   
      console.log(this.ADGroupData);

    })
    }
    /*this._adminPortalServiceService.Get_ADGroupData().subscribe((result: ADGroup[]) => {
      this.ADGroupData = result;
   
      console.log(this.ADGroupData);

    })*/
    //this.azureadgroupid=sessionStorage.getItem('GroupID');
    
    /*this._adminPortalServiceService.Get_ADGroupList(this.azureadgroupid).subscribe((result:ADGroup[]) => {
     
      this.ADGroupData =result;
      
    })*/

    this.cols = [
     { field: 'directoryID', header: 'Edit/Update',width:'8%' },
      { field: 'adGroupID', header: 'ADGroup ID',width:'7%' },
      { field: 'directoryID', header: 'Directory ID',width:'7%' },
      { field: 'azureADGroupID', header: 'Azure ADGroup ID',width:'17%' },
      { field: 'adGroupName', header: 'ADGroup Name',width:'10%' },
      { field: 'bucketName', header: 'Bucket Name',width:'10%' },
      { field: 'directoryPath', header: 'Directory Path',width:'22%' },
      { field: 'folderPath', header: 'Folder Path',width:'10%' },
      { field: 'folderRestriction', header: 'Folder Restriction',width:'10%' }



    ];
    this.displayDialog = false;
  }

  
  showDialogToAdd() {
    this.adminPortal = true;
    this.displayDialog = true;
    this.selected_IsActive = null;
  }
  refresh(): void {
    // window.location.reload();
    this.ngOnInit();
  }

  onRowEditInit(rowData) {
    console.log("RowEditInit" + rowData);

  }
  onRowEditSave(rowData, id, AdminPortalForm) {

    //alert(id);
    if (id > 0) {

      AdminPortalForm.value.adGroupID = AdminPortalForm.controls['adGroupID'].value;
      console.log(AdminPortalForm.value.adGroupID)
      AdminPortalForm.value.DirectoryID = AdminPortalForm.controls['directoryID'].value;
      console.log(AdminPortalForm.value.directoryID);
      AdminPortalForm.value.adGroupName = AdminPortalForm.controls['adGroupName'].value;
      AdminPortalForm.value.bucketName = AdminPortalForm.controls['bucketName'].value;
      AdminPortalForm.value.directoryPath = AdminPortalForm.controls['directoryPath'].value;

      const secret="STl0b*7b6d0&";
      AdminPortalForm.value.awsAccessKey = CryptoJS.AES.encrypt(AdminPortalForm.controls['awsAccessKey'].value,secret).toString();
      AdminPortalForm.value.awsClientID = CryptoJS.AES.encrypt(AdminPortalForm.controls['awsClientID'].value,secret).toString();
      
      
      this._adminPortalServiceService.Put_Directory(AdminPortalForm.value.directoryID, AdminPortalForm.value)
        .subscribe(data => {

          let resultdata = data;
        })
      AdminPortalForm.value.adGroupID = AdminPortalForm.controls['adGroupID'].value;
      console.log(AdminPortalForm.value.adGroupID)
      AdminPortalForm.value.DirectoryID = AdminPortalForm.controls['directoryID'].value;
      console.log(AdminPortalForm.value.directoryID);
      AdminPortalForm.value.adGroupName = AdminPortalForm.controls['adGroupName'].value;
      AdminPortalForm.value.azureADGroupID = AdminPortalForm.controls['azureADGroupID'].value;    
      //AdminPortalForm.value.azureADGroupID=this.azureadgroupid;
      this._adminPortalServiceService.Put_ADGroup(id, AdminPortalForm.value)
        .subscribe(data => {
          let resultdata = data;



          this.messageService.add({ severity: 'info', summary: 'ADGroup  Updated', detail: 'ADGroup  Updated Successfull' });
          this.refresh();


        });
       //alert("Hi");
    }
   

  }
  delete(id: number) {

    this.adgroupid = id;

    /* alert(this.adgroupid);
     alert(this.directoryid);
     this._adminPortalServiceService.Get_ADgroupDirectoryData(id).subscribe((result)=>{
       
       const adgroupdirectorydata = result;
           console.log(adgroupdirectorydata);
           this.directoryid = adgroupdirectorydata[0].DirectoryID;
       console.log(this.directoryid);
       
     })*/

    if (confirm('Are you sure to delete this record ?') == true) {
      this.adgroupid = id;
      if (this.adgroupid != undefined) {
        //alert(this.adgroupid);

        this._adminPortalServiceService.delete_ADGroup(this.adgroupid)
          .subscribe(data => {

            this._adminPortalServiceService.Get_ADGroupData();

            this.ADGroupData.splice(this.adgroupid, 1);
            this.changeDetectorRef.detectChanges();

            this.messageService.add({ severity: 'warn', summary: 'Delete ADGroup', detail: 'ADGroup Deleted Successfull' });
            this.refresh();

          });
      }
    }
  }
  onInput(event: any){
    
    // Get the input's value
    let text = event.target.value;
    // Keep the button disabled if input is empty
    if(text==''){
      this.isDisabled = true;
    }
    else{
      this.isDisabled = false;
    }
  }
  save(addAdminPortalForm) {
   /* if(this.adGroupName !==undefined && this.bucketName !==undefined && this.directoryPath !==undefined && this.newazureadgroupid !==undefined 
    && this.awsaccesskey !==undefined && this.awsclientid !==undefined && this.region !==undefined)
    {*/
     
    addAdminPortalForm.value.adGroupName = this.adGroupName
     addAdminPortalForm.value.azureADGroupID = this.newazureadgroupid
    addAdminPortalForm.value.bucketName = this.bucketName
    addAdminPortalForm.value.region = this.region
    addAdminPortalForm.value.directoryPath = this.directoryPath
   
    addAdminPortalForm.value.awsAccessKey = this.awsaccesskey
    addAdminPortalForm.value.awsClientID = this.awsclientid

    addAdminPortalForm.value.folderPath = this.folderPath
    addAdminPortalForm.value.folderRestriction = this.folderRestriction
    
    /* addAdminPortalForm.value.adGroupName = addAdminPortalForm.controls['_adGroupName'].value;
    addAdminPortalForm.value.bucketName = addAdminPortalForm.controls['_bucketName'].value;
    addAdminPortalForm.value.directoryPath = addAdminPortalForm.controls['_directoryPath'].value;
    addAdminPortalForm.value.azureADGroupID = addAdminPortalForm.controls['_newazureadgroupid'].value;
    addAdminPortalForm.value.awsAccessKey = addAdminPortalForm.controls['_awsaccesskey'].value;
    addAdminPortalForm.value.awsClientID = addAdminPortalForm.controls['_awsclientid'].value;
    addAdminPortalForm.value.region = addAdminPortalForm.controls['_region'].value;*/
    
    const secret="STl0b*7b6d0&";
    addAdminPortalForm.value.awsAccessKey = CryptoJS.AES.encrypt(addAdminPortalForm.controls['_awsaccesskey'].value,secret).toString();
    addAdminPortalForm.value.awsClientID = CryptoJS.AES.encrypt(addAdminPortalForm.controls['_awsclientid'].value,secret).toString();
    this._adminPortalServiceService.post_Directory(addAdminPortalForm.value)
      .subscribe(data => {
        this.directoryData = data;

        this.directoryid = this.directoryData.directoryID;
        addAdminPortalForm.value.directoryID = this.directoryid;
        //alert(this.directoryid)
        this._adminPortalServiceService.post_ADGroup(addAdminPortalForm.value)
          .subscribe(data => {
            this.messageService.add({ severity: 'success', summary: 'Add Admin Portal Records', detail: 'New Admin Portal Records Added Successfull' });
           

            addAdminPortalForm.reset();
             this.refresh();
          })
      })
    /* }
else
    {
      this.messageService.add({severity:'error', summary: 'Error Message', detail:'Fill The Required Field'});

    }*/
}
}