import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MessageService } from 'primeng/api';


import {InteractionStatus, RedirectRequest, PopupRequest, AuthenticationResult} from '@azure/msal-browser';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
import {UserService} from './services/user.service';
import {User} from './models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Envista Cloud Storage';
  isIframe = false;
  loginDisplay = false;
  isLoggedIn = false;
  profile = ''

  private readonly _destroying$ = new Subject<void>();

  authenticated = false;
  displayName = ''
  firstName = ''
  lastName = ''
  email = ''

  user!: User;
  currentLesson: any;
  token:any;
  groupid:any;
  adminuser=false;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private broadcastService: MsalBroadcastService,
              private authService: MsalService, private http: HttpClient, private userServ: UserService)
  {
    this.userServ.authStateObservable.subscribe(state => {


      this.authenticated = state;

    });

   }

  ngOnInit() {

    if (this.authenticated === false) {

      this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;

      if(this.isLoggedIn){

        const activeAccount = this.authService.instance.getAllAccounts()[0]

        this.authService.acquireTokenSilent({
          scopes: ['group.Read.All', 'profile', 'User.Read'],
          account: activeAccount
        }).subscribe(result=>{


          //console.log(result);



          const idToken = result.idTokenClaims


          const idTokenJson = JSON.stringify(idToken)
          const IdTokenObj = JSON.parse(idTokenJson)

          //console.log('id Token3 - app component' + IdTokenObj.groups)

          this.userServ.groups = IdTokenObj.groups

          //alert(IdTokenObj.groups)
          this.groupid=IdTokenObj.groups      
          sessionStorage.setItem('GroupID', IdTokenObj.groups);


          // sessionStorage.setItem('groups', IdTokenObj.groups)

          // alert(result);
          // this.profile = result.account.name
          this.token= result.accessToken
          //alert(this.token);
          //console.log('token' + this.token);
          sessionStorage.setItem('AccessToken', this.token);



          this.userServ.authState.next(true);
          this.setLoginDisplay();

        })


        this.userServ.authState.next(true);
      }

    }


    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })

    this.getProfile();
    //this.login();
  }
  /*getAccessTokenAndCallGraphAPI(){

    this.authService.acquireTokenSilent({
      scopes: ['group.Read.All']
    }).then(result=>{
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Bearer '+result.accessToken
        })}

      this.http.get("https://graph.microsoft.com/v1.0/groups?$select=id,displayName",httpOptions).toPromise().then(result=>{console.log(result)});
    })
  }*/
  /*login() {
    this.authService.loginPopup()
      .subscribe({
        next: (result) => {
          console.log(result);
          this.setLoginDisplay();
        },
        error: (error) => console.log(error)
      });
    }*/

    /*login() {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }*/

    login() {
      if (this.msalGuardConfig.authRequest){
        //alert("Hello")
        this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe({
            next: (result) => {
              console.log(result);
             // alert(result);
              this.profile = result.account.name
              this.token=result.accessToken
              //alert(this.token);
              //console.log("token"+this.token);
              sessionStorage.setItem('AccessToken', this.token);


              const idToken = result.idTokenClaims


              const idTokenJson = JSON.stringify(idToken)
              const IdTokenObj = JSON.parse(idTokenJson)

              //console.log('id Token1 - app component' + IdTokenObj.groups)
             
              this.userServ.groups = IdTokenObj.groups

              this.userServ.authState.next(true);
              this.setLoginDisplay();

            },
            error: (error) => console.log(error)
          });
          /*console.log("token"+this.token);
          sessionStorage.setItem('AccessToken', this.token);*/
      } else {
        //alert("Else")
        this.authService.loginPopup()
          .subscribe({
            next: (result) => {
              console.log(result);
              this.profile = result.account.name
              this.token=result.accessToken
              //console.log("token"+this.token);
              sessionStorage.setItem('AccessToken', this.token);
              //alert(this.token);
              /*console.log("token"+this.token);
              sessionStorage.setItem('AccessToken', this.token);*/
              this.setLoginDisplay();
             
            },
            error: (error) => console.log(error)
          });
      }
    }
  

   /* logout() { // Add log out function here
      this.authService.logoutRedirect({
        postLogoutRedirectUri: 'http://localhost:4200'
      });
    }*/
    logout() { // Add log out function here
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    }
    setLoginDisplay() {
      //alert("Hi");
      this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }
    ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
    }
   
    getProfile() {
      this.http.get(GRAPH_ENDPOINT)
          .subscribe(profile => {
            //console.log("testete"+profile)
            this.user = profile;
            this.userServ.userSubject.next(this.user)
  
            if(this.user != null){
  
              this.displayName = this.user.displayName
              this.email = this.user.mail
              this.firstName = this.user.givenName
              this.lastName = this.user.surname
              
              if(this.email=='Chris.Tobler@envistaco.com'||this.email=='chris.tobler@envistaco.com'||this.email=='diego.muniz@envistaco.com'|| this.email=='Diego.Muniz@envistaco.com' || this.email=='Yonathan.Seleshi@envistaco.com'||this.email=='yonathan.seleshi@envistaco.com' || this.email=='sandeep.jamkar@envistaco.com')
              {
                //alert("call ryt")
                
                this.currentLesson = '1';
                this.adminuser = true;
              }
              if(this.groupid.includes("652fa8b7-cd5d-417b-929c-7185c3356240"))
              {
                 this.adminuser = true;
                 this.currentLesson = '1';
              }
              
            }
  
          });
         
    
}
}
