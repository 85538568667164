import Amplify, { Auth, Storage } from 'aws-amplify';

Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:d7200b03-a744-4789-bf42-39b380fb911c', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        
    },
    Storage: {
        AWSS3: {
            bucket: 's3-search-app-dev', //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-1', //OPTIONAL -  Amazon service region
        }
    }
    
});
export const awsconfig =  {
    Auth: {
        identityPoolId: 'us-east-1:d7200b03-a744-4789-bf42-39b380fb911c', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        
    },
    Storage: {
        AWSS3: {
            bucket: 's3-search-app-dev', //REQUIRED -  Amazon S3 bucket name
            region: 'us-west-2', //OPTIONAL -  Amazon service region
        }
    }
}