export class ADGroup {
    adGroupID:number;  
    directoryID:number;
    adGroupName:string;
    bucketName:string;
    awsAccessKey:string;
    awsClientID:string;
    directoryPath:string;
    region:string;
    azureADGroupID:string;
    dateOfUpload:Date;
    folderPath:string;
    folderRestriction:boolean
}
