import {Component, OnInit} from '@angular/core';

import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {EventMessage, EventType, InteractionStatus, AuthenticationResult} from '@azure/msal-browser';
import {filter} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {User} from '../models/user.model';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

interface ProfileType {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  mail?: string
  id?: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loginDisplay = false;

  authenticated = false;
  displayName = ''
  firstName = ''
  lastName = ''
  email = ''
  name = ''

  user!: User;

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private userServ: UserService,
              private http: HttpClient,
              private router: Router) {

    this.user = new User()

    this.userServ.authStateObservable.subscribe(state => {


      this.authenticated = state;
      if(this.authenticated)
      {
        this.router.navigate(['/bucket']);
      }
      else{
        this.router.navigate(['/']);
      }

    });


  }

  ngOnInit(): void {


    // if(this.authenticated)
    // {
    //   this.router.navigate(['/bucket']);
    // }
    // else{
    //   this.router.navigate(['/']);
    // }


    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        if (result.eventType === EventType.LOGIN_SUCCESS) {

          const payload = result.payload as AuthenticationResult

          const account = payload.account

          const idToken = account.idTokenClaims

          console.log('id Token' + idToken)

          this.userServ.authState.next(true);
          this.getProfile()

        }else{

          this.userServ.authState.next(false);
        }

      });


    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
        .subscribe((profile: ProfileType) => {

          if(profile !== null) {


            this.user.id = profile.id;
            this.user.surname = profile.surname
            this.user.givenName = profile.givenName
            this.user.mail = profile.mail
            this.user.userPrincipalName = profile.userPrincipalName

            this.userServ.userSubject.next(this.user)


            if (this.user !== null) {
              console.log('display name: ' + this.user.displayName + ' email: ' + this.user.mail)
              this.displayName = this.user.displayName
              this.email = this.user.mail
              this.firstName = this.user.givenName
              this.lastName = this.user.surname
            }

          }

        });
  }

}
