import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';

import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from 'src/environments/environment';
import { ADGroup } from './adgroup.model'
import { Directory } from './directory.model'

@Injectable({
  providedIn: 'root'
})
export class AdminPortalServiceService {
 
  API_ENDPOINT = '';
  // API_ENDPOINTAPI = environment.baseUrlDb;


  
  _adGroupList: ADGroup[];
  _adGroup: ADGroup;

  _directoryList: Directory[];
  _directory: Directory;

  constructor(private httpclient: HttpClient) { 


     if (environment.production) {
     
     this.API_ENDPOINT = environment.baseUrl
      
    }else{

        this.API_ENDPOINT = environment.baseUrlDb
    }


  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  /* public Get_ADGroupData() {
        return this.httpclient.get(this.API_ENDPOINT+'adgroup');
   }*/
  public Get_ADGroupData() {



        return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupDirectoryData');
      

  
  }
  public Get_ADgroupDirectoryData(id) {

  
       
      return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupDirectoryDatabyID' + id);

  }
  post_ADGroup(article: ADGroup): Observable<ADGroup> {
    console.log("Call Post data");

 

         return this.httpclient.post<ADGroup>(this.API_ENDPOINT + "ADGroup", article);
   
    
  }
  post_Directory(article: Directory): Observable<Directory> {
    console.log("Call Post data");

  
     return this.httpclient.post<Directory>(this.API_ENDPOINT + "Directory", article);

   
  }

  Put_ADGroup(id, _adGroup): Observable<ADGroup> {
    console.log("Call Put data");

  
      
       const putUrl = this.API_ENDPOINT + "ADGroup" + '/' + id;
       return this.httpclient.put<ADGroup>(putUrl, _adGroup, this.httpOptions);
 

  
  }
  Put_Directory(id, _directory): Observable<Directory> {
    console.log("Call Put data");


     const putUrl = this.API_ENDPOINT + "Directory" + '/' + id;
    return this.httpclient.put<Directory>(putUrl, _directory, this.httpOptions);

 
   
  }
  delete_ADGroup(id): Observable<{}> {
    console.log("Call delete data");

   

        const deleteUrl = this.API_ENDPOINT + "ADGroup" + '/' + id;
    return this.httpclient.delete(deleteUrl, this.httpOptions);
  

  }

  delete_Directory(id): Observable<{}> {
    console.log("Call delete data");


        const deleteUrl = this.API_ENDPOINT + "Directory" + '/' + id;
        return this.httpclient.delete(deleteUrl, this.httpOptions);

    
  }
  public Get_ADGroupList(id:any) {



      return this.httpclient.get(this.API_ENDPOINT + 'adgroup/GetADGroupList?azureadgroupid='+id);
      

   
  }
 
}
