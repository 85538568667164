import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgModule, } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"; // Import 

import { TableModule } from 'primeng/components/table/table';
import { RadioButtonModule } from 'primeng/components/radiobutton/radiobutton';
import { ButtonModule } from 'primeng/components/button/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ToastModule } from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressBarModule} from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import Amplify, { Storage } from 'aws-amplify';

//import awsconfig from '../aws-exports'; 
//Amplify.configure(awsconfig);



/*import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';*/

/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';

/*add MsalModule and MsalInterceptor to imports as well as the isIE constant*/ 


import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
//import { MsalModule, MsalRedirectComponent, MsalGuard } from '@azure/msal-angular'; // MsalGuard added to imports
import { PublicClientApplication, InteractionType } from '@azure/msal-browser'; // InteractionType added to imports



import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular';

import { AWSS3BucketSearchComponent } from './awss3-bucket-search/awss3-bucket-search.component'; // Import MsalInterceptor
import {UserService} from './services/user.service'; // Import MsalInterceptor



const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
import { FormsModule }   from '@angular/forms';
import { HttpClient} from '@angular/common/http';

import { S3Client, AbortMultipartUploadCommand } from "@aws-sdk/client-s3";
import { AdminportalComponent } from './adminportal/adminportal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    AWSS3BucketSearchComponent,
    AdminportalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TableModule,
    RadioButtonModule,
    HttpClientModule,
    //FormsModule,
    BrowserAnimationsModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,

    /*MatButtonModule,
    MatToolbarModule,
    MatListModule,*/

    //added 11 Dec 2019 
    TabMenuModule,
    TabViewModule,
    ProgressSpinnerModule,
    //CodeHighlighterModule,
    //end added 11 Dec 2019
    ToastModule,//added 16 dec 2019,
    TooltipModule ,
    FormsModule ,
    //ProgressBarModule//added 21 feb 2020
    /* configure app with AmplifyUIAngularModule */
    AmplifyUIAngularModule,

    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: '6efacf0e-f530-41c7-a115-598b0c509053',//Enter_the_Application_Id_here', // This is your client ID
        authority:'https://login.microsoftonline.com/137b3b98-f315-4025-ae27-3d17e9bf0d66',// '137b3b98-f315-4025-ae27-3d17e9bf0d66',//Enter_the_Cloud_Instance_Id_Here',//'Enter_the_Tenant_Info_Here', // This is your tenant ID
        redirectUri:'https://envistacloudstorage.com' //'https://awssearch-dev.azurewebsites.net/auth' //'Enter_the_Redirect_Uri_Here'// This is your redirect URI
        // 'https://awssearch-dev.azurewebsites.net/.auth/login/aad/callback'

      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }),  {
      interactionType: InteractionType.Popup, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
  },  {
    interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
    protectedResourceMap: new Map([ 
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
    ])
  }), 
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard, // MsalGuard added as provider here
      UserService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
