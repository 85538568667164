import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify, { Auth,Storage } from 'aws-amplify';
//import awsconfig from '../../aws-exports';
  //Amplify.configure(awsconfig);

  //import Amplify from 'aws-amplify';
import * as awsamplify from './aws-exports';
Amplify.configure(awsamplify.awsconfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
