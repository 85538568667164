import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {User} from '../models/user.model';
import {UserService} from '../services/user.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';




import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthError } from '@azure/msal-common/dist/error/AuthError';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { IdToken } from '@azure/msal-common';

interface ProfileType {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  displayName?: string
  mail?: string
  id?: string;
}

//const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Here/v1.0/me';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;

  userObj: User = new User();
  token:any;
  groupid:any;
  azureadgroupid:any;
  adgroupname:any;
  constructor( private http: HttpClient, private userServ: UserService,private authService: MsalService) {

      this.userObj = this.userServ.currentUser;

      this.userServ.userObservable.subscribe( u => {

      this.userObj = u;

     

    });

  }

  ngOnInit() {

    this.getProfile();

    /*this.token = sessionStorage.getItem('AccessToken');
    this.groupid = sessionStorage.getItem('GroupID');
    this.adgroupname=sessionStorage.getItem('ADGroupName');
    this.azureadgroupid=sessionStorage.getItem('AzureADGroupID');*/
    this.getAccessTokenAndCallGraphAPI()
    
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        if (this.profile != null) {

          this.userObj.userPrincipalName = this.profile.userPrincipalName
          this.userObj.mail = this.profile.mail
          this.userObj.givenName = this.profile.givenName
          this.userObj.surname = this.profile.surname
          this.userObj.displayName = this.profile.displayName
          this.userObj.id = this.profile.id
          this.userServ.userSubject.next(this.userObj)

        }


      });
  }

  getAccessTokenAndCallGraphAPI(){



    {
      scopes: ['group.Read.All']
      //scopes: ['user.read'],
      //account: accounts[0]

    }
    this.authService.acquireTokenSilent({
      scopes: ['group.Read.All']
      //scopes: ['user.read'],
      //account: accounts[0]

    }).subscribe(result=>{
      console.log("result token" + result)
      const httpOptions = {
        
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Bearer ' + result //.accessToken

        })}


        this.http.get('https://graph.microsoft.com/v1.0/me/groups', httpOptions).toPromise().then(result => {console.log('Get Groups result: ' + result)});
       //this.http.get(GRAPH_ENDPOINT,httpOptions).toPromise().then(result=>{console.log(result)});
      //this.http.get("https://graph.microsoft.com/v1.0/groups?$select=id,displayName",httpOptions).toPromise().then(result=>{console.log(result)});
      
    })
    
  }
  
}


